import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { sepolia } from 'wagmi/chains';
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import MetaMaskProvider from "./utils/MetamaskProvider";



const projectId = '1ee662092220d9e279a243cd7270bbae';

const { chains, webSocketPublicClient } = configureChains(
  [sepolia, mainnet],
  [alchemyProvider({ apiKey: process.env.REACT_APP_API_KEY }), publicProvider()],
)



const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])

const _metamask = new MetaMaskConnector({ chains });
const _coinbase = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'wagmi',
  },
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  _metamask,
  _coinbase,
  publicClient,
  webSocketPublicClient

})

const ethereumClient = new EthereumClient(wagmiConfig, chains);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/*<MetaMaskProvider>*/}
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient}></Web3Modal>
    {/*</MetaMaskProvider>*/}
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
