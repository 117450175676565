/* eslint-disable no-undef */
import '../App.css';
import React, { useEffect, useState } from 'react';
import { useContractRead } from 'wagmi';
import magicEden from '../assets/me.png';
import twitter from '../assets/x.png';
import scan from '../assets/scan.png';
import logo from '../assets/3.jpg';
import logoUp from '../assets/Logo-up.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import Footer from '../Components/footer';
import contractABI from '../json/contractAbi.json';

const Awards = () => {

    const [_btnAwards, set_btnAwards] = useState(0)
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [tokenID, setTokenID] = useState('');
    const [rewards, setRewards] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [_supply, setSupply] = useState(false);
    const [_tokenMinterAwardsActive, setTokenMinterAwardsActive] = useState(false);
    const [msg, setMsg] = useState('');
    
    const magicEdenVar = process.env.REACT_APP_MAGIC_EDEN;
    const twitterVar = process.env.REACT_APP_TWITTER;
    const scanVar = process.env.REACT_APP_SCAN;

    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;

    const magicEdenL = () => {
        window.open(magicEdenVar);
    }

    const twitterL = () => {
        window.open(twitterVar);
    }

    const scanL = () => {
        window.open(scanVar);
    }

    async function closeNav() {
        set_navbarOpen(0);
    }

    async function navbarOpen() {
        set_navbarOpen(1);
    }

    const contract = {
        address: contractAddress,
        abi: contractABI.abi
    }

    useEffect(() => {

        async function fetchData() {
            var data = await getTokenMinterAwardsActive();

            setTokenMinterAwardsActive(data.data)
        }

        fetchData();

        console.log("magic eden : " + magicEdenVar);
        console.log("twitter : " + twitterVar);
        console.log("scan : " + scanVar);

    }, [tokenID, _tokenMinterAwardsActive])


    const handleInputChange = (event) => {
        const value = event.target.value;

        // Convert value to a number to perform numeric checks
        const numericValue = Number(value);

        // Check if the value is a positive number and does not exceed 10000
        if (numericValue > 0 && numericValue <= 10000) {
            setTokenID(value);
        } else if (numericValue > 10000) {
            setTokenID(10000);
        } else {
            setTokenID(''); // Reset the tokenID if it is 0 or negative
        }
    };

    const { refetch: getRewards } = useContractRead({
        ...contract,
        functionName: 'getTokenMinterAward',
        args: [tokenID]
    })

    const { refetch: getTokenMinterAwardsActive } = useContractRead({
        ...contract,
        functionName: 'tokenMinterAwardsActive'
    })

    const fetchRewards = async () => {
        try {
            const data2 = await getRewards();

            setRewards(data2.data);
            setMsg(`${data2.data}`);
            console.log("msg Read : " + data2.data);

            set_btnAwards(1);

        } catch (error) {
            console.log(error.message)
            // setMsg(`Error fetching rewards: ${error.message}`);
        }
    };

    return (
        <div class="allWrap2">
            <div class="light2">
                <div class="cont">
                    {_navbarOpen < 1 ?
                        (<div class="miniBarMain">
                            <div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                        </div>) :
                        (<div class="miniBarMain">
                            <div class="logo"><img onClick={() => window.location.href = '/'} class="logoPic" src={logo} /></div>

                            <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                        </div>)}

                    {_navbarOpen > 0 ?
                        (<div class="littleNav">
                            <div class="icons">
                                <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                <div class="navDiv" onClick={() => window.location.href = 'faq'}>FAQ</div>
                                <div className='iconsMain'>
                                    <div class="socialIcon"><img onClick={magicEdenL} src={magicEden} /></div>
                                    <div class="socialIcon"><img onClick={twitterL} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={scanL} src={scan} /></div>
                                </div>
                            </div>

                        </div>) : null}

                    <div class="headers">

                        <div class="headers2">

                            <div class="logo" onClick={() => window.location.href = '/'}>
                                <img class="logoPic" src={logo} />
                                <img class="logoUp" src={logoUp} />

                            </div>

                            <div class="right">

                                <div class="icons">
                                    <div class="navDiv" onClick={() => window.location.href = '/'}>HOME</div>
                                    <div class="navDiv" onClick={() => window.location.href = 'faq'}>FAQ</div>
                                    <div class="socialIcon"><img onClick={magicEdenL} src={magicEden} /></div>
                                    <div class="socialIcon"><img onClick={twitterL} src={twitter} /></div>
                                    <div class="socialIcon"><img onClick={scanL} src={scan} /></div>
                                </div>

                            </div>

                        </div>

                    </div>

                    {_tokenMinterAwardsActive ?
                        <div className='inputMain'>
                            <div className='inputMainSub'>
                                <div className='inputT'>View Token Minter Awards</div>
                                <input
                                    type='number'
                                    placeholder='Token Key'
                                    value={tokenID}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <button onClick={fetchRewards} className='submitBtn'>Check</button>

                            {tokenID !== '' && _btnAwards > 0 &&
                                (msg === "undefined" && _btnAwards > 0 ?
                                    <div className='msg2'>
                                        The token entered has not been minted yet so we are unable to confirm any award
                                    </div> :
                                    <div className='msg'>{msg}</div>
                                )
                            }

                        </div> : 
                        <div className='inputMain'>
                            <center><div className='inputT'>Minter awards cannot currently be checked</div></center>
                        </div>
                        }


                </div>
            </div >

            <Footer />

        </div >
    )

}

export default Awards;
