import React from "react";
import { MetaMaskProvider } from "@metamask/sdk-react";


 
const MetamaskProvider = ({ children }) => {

  

  return (
    <>
      <MetaMaskProvider
        debug={true}
        sdkOptions={{
          dappMetadata: {
            name: "Cockadoodles",
            url: "https://hippocroco-dapp.vercel.app/",
            //iconUrl: icon, // optional
          },
            infuraAPIKey: process.env.REACT_APP_API_KEY,
        }}
      >
        {children};
      </MetaMaskProvider>
    </>
  );
};
 
export default MetamaskProvider;
